import { Box, Stack } from '@mui/material';

// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import arrowIcon from '/static/arrow-icon.svg';
// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import autoneLogoText from '/static/autone-logo-text.svg';

import { DashedBorderLine } from './index';

const AuthHeader = () => {
  return (
    <Box
      sx={{
        px: '52px',
        pt: '30px',
      }}
    >
      <Stack
        direction="row"
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{ pb: 3 }}
      >
        <img
          style={{
            width: '113px',
            height: '24px',
          }}
          src={autoneLogoText}
          color={'blue'}
          alt={'logo'}
        />
        <img
          src={arrowIcon}
          alt={'arrow-icon'}
          style={{
            width: '32px',
            height: '32px',
          }}
        />
      </Stack>
      <DashedBorderLine />
    </Box>
  );
};

export default AuthHeader;
